import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useTranslate, Title } from 'react-admin';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import generalConfig from '../../config/generalConfig';
import resourcesConfig from '../../config/resourcesConfig.json';
import userManual from '../../resources/Manual-de-Usuario-TantAI.pdf';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: { marginTop: '20px' }
});

const Help = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const showUserManual = resourcesConfig.general.showUserManual;

    return (
        <Card className={classes.root}>
            <Title title="pos.help.name" />
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {translate('pos.help.about.title')}
                        </Typography>
                        <Typography>
                            {translate('pos.help.about.text')}
                        </Typography>
                        <Typography>
                            {translate('pos.help.about.version', { version: generalConfig.version })}
                        </Typography>
                        <Typography>
                            {translate('pos.help.about.versionBuild', { version_build: generalConfig.versionBuild })}
                        </Typography>
                        <Typography>
                            {translate('pos.help.about.versionDate', {
                                version_date: moment.utc(generalConfig.versionDate, 'YYYY-MM-DD HH:mm:ss')
                                    .local().format(translate('pos.datetime.datetime'))
                            })}
                        </Typography>
                        <Typography>
                            {translate('pos.help.about.homepage')}
                            <a href="https://www.33sur.cl/" target="_blank" rel="noopener noreferrer">
                                www.33sur.cl
                            </a>
                        </Typography>
                        <Typography>
                            {translate('pos.help.about.email')}
                            <a href="mailto:tantai@33sur.cl" target="_blank" rel="noopener noreferrer">
                                tantai@33sur.cl
                            </a>
                        </Typography>
                    </Grid>
                    {showUserManual &&
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {translate('pos.help.userManual')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <object data={userManual} type="application/pdf" width="100%" height="650 px">
                                    <a href={userManual}>{translate('pos.generic.download')}</a>
                                </object>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Help;
