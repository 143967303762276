import * as React from 'react';
import { useContext, useMemo, useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import {
    Button as RaButton, NumberField, TextField, SelectInput, NumberInput, BooleanInput, ReferenceManyField, Datagrid,
    downloadCSV, useLocale, useTranslate, useDataProvider
} from 'react-admin';
import {
    Button, Checkbox, CircularProgress, Collapse, Grid, IconButton, MenuItem, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowRight as KeyboardArrowRightIcon,
    Visibility as VisibilityIcon
} from '@material-ui/icons';
import { Form } from 'react-final-form';
import ExportModal from './ExportModal';
import DurationField from '../../../custom/DurationField';
import TransactionList from '../TransactionList';
import TransactionShowButton from '../TransactionShowButton';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import CustomTable from '../../../custom/CustomTable';
import CustomTableToolbar from '../../../custom/CustomTableToolbar';
import CustomReferenceField from '../../../custom/CustomReferenceField';
import CustomDateTimeField from '../../../custom/CustomDateTimeField';
import getGroupByDefaultValue from '../../../../helpers/getGroupByDefaultValue';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import getGroupByChoices from '../../../../helpers/getGroupByChoices';
import { AbilityContext } from '../../../Can';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import moment from 'moment';
import humanize from '../../../../config/humanizeDuration';
import { stringify } from 'qs';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import _ from 'lodash';
import clsx from  'clsx';
import * as XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableColumnHighlight: { backgroundColor: theme.palette.grey['100'] },
    totalCell: { fontWeight: 500 },
    headerCell: {
        top: '-17px'
    }
}));

const groupByChoices = [
    {
        id: 'transaction.operatorCode',
        name: 'resources.transactions.fields.operatorCode',
        altName: 'resources.transactions.fields.operatorCode',
        fieldId: 'transaction.operatorCode',
        fieldDataId: 'operator.name',
        fieldDataTranslate: 'resources.transactions.fields.operatorName',
        withPrecision: false,
        withLimit: true
    },
    {
        id: 'terminalType.id',
        name: 'resources.transactions.fields.terminalType',
        altName: 'resources.transactions.fields.terminalType',
        fieldId: 'terminalType.id',
        fieldDataId: 'terminalType.name',
        fieldDataTranslate: 'resources.transactions.fields.terminalType',
        withPrecision: false,
        withLimit: true
    },
    {
        id: 'transaction.terminalNumber',
        name: 'resources.transactions.fields.terminalNumber',
        altName: 'resources.transactions.fields.terminalNumber',
        fieldId: 'transaction.terminalNumber',
        fieldDataId: 'transaction.terminalNumber',
        fieldDataTranslate: 'resources.transactions.fields.terminalNumber',
        withPrecision: false,
        withLimit: true
    },
    {
        id: 'transaction.storeCode',
        name: 'resources.transactions.fields.storeCode',
        altName: 'resources.transactions.fields.storeCode',
        fieldId: 'transaction.storeCode',
        fieldDataId: 'transaction.storeCode',
        fieldDataTranslate: 'resources.transactions.fields.storeCode',
        withPrecision: false,
        withLimit: false
    },
    {
        id: 'transaction.storeCodeAndDatetime',
        name: 'resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.storeCodeAndDatetime',
        altName: 'resources.transactions.fields.storeCode',
        fieldId: 'transaction.storeCode',
        fieldDataId: 'transaction.storeCode',
        fieldDataTranslate: 'resources.transactions.fields.storeCode',
        groupByDatetime: true,
        withPrecision: true,
        withLimit: false
    },
    {
        id: 'chain.id',
        name: 'resources.stores.fields.chain',
        altName: 'resources.stores.fields.chain',
        fieldId: 'chain.id',
        fieldDataId: 'chain.name',
        fieldDataTranslate: 'resources.stores.fields.chain',
        withPrecision: false,
        withLimit: true
    }
];

const timeTypes = [
    {
        id: 'ringElapsedTime',
        type: 'transactionTime',
        name: 'resources.transactions.fields.ringElapsedTime',
        abbrName: 'resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.ringElapsedTime'
    },
    {
        id: 'tenderElapsedTime',
        type: 'transactionTime',
        name: 'resources.transactions.fields.tenderElapsedTime',
        abbrName: 'resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.tenderElapsedTime'
    },
    {
        id: 'inactiveElapsedTime',
        type: 'inactiveTime',
        name: 'resources.transactions.fields.inactiveElapsedTime',
        abbrName: 'resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.inactiveElapsedTime'
    },
    {
        id: 'specialElapsedTime',
        type: 'inactiveTime',
        name: 'resources.transactions.fields.specialElapsedTime',
        abbrName: 'resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.specialElapsedTime'
    }
];

const dateFormatter = translate => (precision, value) => {
    switch (precision) {
        case 'year':
            return moment(value).format('YYYY');
        case 'month':
            return moment(value).format(translate('pos.datetime.date').replace(/-DD|DD-/, ''));
        case 'day':
            return moment(value).format(translate('pos.datetime.date'));
        case 'hour':
            return moment(value).format(`${translate('pos.datetime.date')} HH:00`);
        case 'minute':
        default:
            return moment(value).format(translate('pos.datetime.datetime'));
    }
};

const getStartAndEndDateTimeTransaction = transaction => {
    let transactionTimes = {
        startDateTime: moment.utc(transaction.businessDayDate, 'YYYY-MM-DD HH:mm:ss'),
        endDateTime: null
    };

    if (transaction.userStrings) {
        const userString = transaction.userStrings.find(e => e.originator === '06' && e.subtype === '20');
        if (userString) {
            const startDateTime = `${userString.userData.date} ${userString.userData.time}`;
            transactionTimes.startDateTime = moment.utc(startDateTime, 'YYYY-MM-DD HH:mm:ss');
        }
    }

    let totalTransactionTime = 0;
    timeTypes.forEach(timeType => {
        if (timeType.type === 'transactionTime' && timeType.id in transaction) {
            totalTransactionTime += transaction[timeType.id];
        }
    });
    transactionTimes.endDateTime = transactionTimes.startDateTime.clone().add(totalTransactionTime, 'seconds');

    return transactionTimes;
};

const humanizeTime = locale => value => {
    let valueAsMilliseconds = moment.duration(value, 'seconds').asMilliseconds();
    return humanize(valueAsMilliseconds, {
        shortFormat: true,
        language: locale,
        largest: 3
    });
};

const formatDateTime = translate => value => {
    return moment(value, 'YYYY-MM-DD HH:mm:ss').format(translate('pos.datetime.datetime'));
};

const getDetailHeadCells = (translate, locale) => {
    return [
        {
            sort: 1,
            id: 'storeCode',
            label: translate('resources.transactions.fields.storeCode'),
        },
        {
            sort: 2,
            id: 'chain.name',
            label: translate('resources.chains.name', 1),
        },
        {
            sort: 3,
            id: 'operatorCode',
            label: translate('resources.transactions.fields.operatorCode'),
        },
        {
            sort: 4,
            id: 'operator.name',
            label: translate('resources.transactions.fields.operatorName'),
        },
        {
            sort: 5,
            id: 'terminalNumber',
            label: translate('resources.transactions.fields.terminalNumber'),
        },
        {
            sort: 6,
            id: 'sequenceNumber',
            label: translate('resources.transactions.fields.sequenceNumber'),
        },
        {
            sort: 7,
            id: 'businessDayDate',
            label: translate('resources.transactions.fields.businessDayDate'),
            formatter: formatDateTime(translate)
        },
        {
            sort: 8,
            id: 'startDateTime',
            label: translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.startTransaction'),
            formatter: formatDateTime(translate)
        },
        {
            sort: 9,
            id: 'endDateTime',
            label: translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.endTransaction'),
            formatter: formatDateTime(translate)
        },
        {
            sort: 10,
            id: 'ringElapsedTime',
            label: translate('resources.transactions.fields.ringElapsedTime'),
            formatter: humanizeTime(locale)
        },
        {
            sort: 11,
            id: 'tenderElapsedTime',
            label: translate('resources.transactions.fields.tenderElapsedTime'),
            formatter: humanizeTime(locale)
        },
        {
            sort: 12,
            id: 'specialElapsedTime',
            label: translate('resources.transactions.fields.specialElapsedTime'),
            formatter: humanizeTime(locale)
        },
        {
            sort: 13,
            id: 'inactiveElapsedTime',
            label: translate('resources.transactions.fields.inactiveElapsedTime'),
            formatter: humanizeTime(locale)
        }
    ]
};

const getKey = groupBy => e => {
    let key = '';
    if (['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id)) {
        key += `${e[groupBy.fieldId]}-${e['chain.id']}-${e['transaction.storeCode']}`;
    } else {
        key += e[groupBy.fieldId];
    }
    return key;
};

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const ability = useContext(AbilityContext);

    const [groupBy, setGroupBy] = useState(groupByChoices.find(e => e.id === filters.groupById));
    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const checkedGroupByChoices = useMemo(() => {
        const groupByIds = getGroupByChoices(
            groupByChoices.map(e => e.fieldId), 'general-times', ability.rulesFor('find', 'statistics')
        );
        return groupByChoices.filter(e => groupByIds.includes(e.fieldId));
    }, [ability]);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="groupById"
                                    label="pos.generic.groupBy"
                                    choices={checkedGroupByChoices}
                                    onChange={({ target }) => {
                                        const { value } = target;
                                        setGroupBy(groupByChoices.find(e => e.id === value));
                                    }}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value &&
                                        _.isArray(form.getFieldState('chainId').value) ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="terminalTypeId"
                                    record={filters}
                                    reference="terminalTypes"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.transactions.fields.terminalType')}
                                    withNull={true}
                                    helperText={false}
                                    //groupByField="chainId"
                                    additionalFilter={{
                                        ...(form.getFieldState('chainId') && form.getFieldState('chainId').value &&
                                            _.isArray(form.getFieldState('chainId').value) ? {
                                            chainId: form.getFieldState('chainId').value
                                        } : {}),
                                        ...(form.getFieldState('storeCode') && form.getFieldState('storeCode').value &&
                                            _.isArray(form.getFieldState('storeCode').value) ? {
                                            storeCode: form.getFieldState('storeCode').value
                                        } : {})
                                    }}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="orderType"
                                    label="pos.generic.order.orderType.name"
                                    choices={[
                                        { id: 'ASC', name: 'pos.generic.order.orderType.asc' },
                                        { id: 'DESC', name: 'pos.generic.order.orderType.desc' },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    source="limit"
                                    label="pos.generic.limit"
                                    min="1"
                                    disabled={!groupBy.withLimit}
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    source="timeLimit"
                                    label="pos.generic.timeLimit"
                                    min="1"
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="precision"
                                    label="pos.generic.precision"
                                    choices={[
                                        { id: 'hour', name: translate('pos.datetime.hour', 2) },
                                        { id: 'day', name: translate('pos.datetime.day', 2) },
                                        { id: 'month', name: translate('pos.datetime.month', 2) },
                                        { id: 'year', name: translate('pos.datetime.year', 2) },
                                    ]}
                                    disabled={!groupBy.withPrecision}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <BooleanInput
                                    source="ignoreZeroTimes"
                                    label="resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.ignoreZeroTimes"
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.name')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                groupById: getGroupByDefaultValue(
                                    groupByChoices.map(e => e.id), 'general-times',
                                    ability.rulesFor('find', 'statistics'), 'transaction.operatorCode'
                                ),
                                ignoreZeroTimes: true,
                                precision: 'day',
                                businessDayDate: getBusinessDayDateDefaultValue(),
                                orderType: 'ASC',
                                limit: 25,
                                timeLimit: 5
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.name')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    chart: Chart,
                    mainTable: ExportableDataTable
                }}
            />
        </div>
    );
};

const Chart = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles({ exportable });

    const [processedData, setProcessedData] = useState([]);
    const [timeTypesTranslated] = useState(timeTypes.map(e => ({
        id: e.id,
        name: translate(e.name)
    })));

    let { groupById, chainId, storeCode } = filters;

    const groupBy = useMemo(() => {
        return groupByChoices.find(e => e.id === groupById);
    }, [groupById]);

    useEffect(() => {
        if (data && data.length > 0 && !loading && !cancel) {
            if (!['transaction.storeCode', 'transaction.storeCodeAndDatetime'].includes(groupBy.id)) {
                setProcessedData(data.map(e => {
                    let groupByValue = getKey(groupBy)(e);
                    let obj = {
                        id: groupByValue,
                        [groupBy.fieldId]: e[groupBy.fieldId],
                        [groupBy.fieldDataId]: e[groupBy.fieldDataId],
                        totalTime: 0,
                        ...(['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) && {
                            'chain.id': e['chain.id'],
                            'chain.name': e['chain.name'],
                            'transaction.storeCode': e['transaction.storeCode'],
                            unknown: !e[groupBy.fieldDataId]
                        })
                    };

                    timeTypesTranslated.forEach(timeTypeTranslated => {
                        if (timeTypeTranslated.id in e) {
                            obj[timeTypeTranslated.name] = e[timeTypeTranslated.id];
                            obj.totalTime += e[timeTypeTranslated.id];
                        }
                    });
                    return obj;
                }));
            } else {
                let processedData = [];
                const groupByValues = _.uniq(data.map(e => e[groupBy.fieldId]));
                groupByValues.forEach(groupByValue => {
                    let filteredData = data.filter(e => e[groupBy.fieldId] === groupByValue);
                    let groupByData = {
                        id: groupByValue,
                        [groupBy.fieldId]: groupByValue,
                        totalTime: 0
                    };

                    timeTypesTranslated.forEach(timeTypeTranslated => {
                        let totalTime = _.sumBy(filteredData, timeTypeTranslated.id);
                        groupByData[timeTypeTranslated.name] = totalTime;
                        groupByData.totalTime += totalTime;
                    });

                    processedData.push(groupByData);
                });

                setProcessedData(processedData);
            }
        }
    }, [translate, timeTypesTranslated, data, loading, cancel, groupBy, chainId]);

    if (loading) {
        return (
            <div className={classes.chart}>
                <CircularProgress />
            </div>
        );
    }
    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noChart')} />
        );
    }
    if (!data || data.length === 0 || cancel) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const axisBottom = value => {
        const groupByData = processedData.find(e => e.id === value);
        if (!groupByData || !(groupBy.fieldId in groupByData)) {
            return value;
        }

        let groupByValue = groupByData[groupBy.fieldDataId];
        if (groupBy.id === 'terminalType.id') {
            if ((storeCode && storeCode.length !== 1) || !storeCode) {
                if (groupByData.unknown) {
                    groupByValue = `${translate('resources.transactions.fields.withoutTerminalType')} (${groupByData['transaction.storeCode']})`;
                } else {
                    groupByValue = `${groupByData[groupBy.fieldDataId]} (${groupByData['transaction.storeCode']})`;
                }
            } else {
                if (groupByData.unknown) {
                    groupByValue = translate('resources.transactions.fields.withoutTerminalType');
                } else {
                    groupByValue = groupByData[groupBy.fieldDataId];
                }
            }

            if (groupByValue.length > 15) {
                return (
                    <tspan>
                        <tspan x="0" dy="10">{groupByValue.substring(0, 15)}-</tspan>
                        <tspan x="0" dy="15">{_.truncate(groupByValue.substring(15), { length: 15 })}</tspan>
                    </tspan>
                );
            }
        } else if (groupBy.id === 'transaction.operatorCode') {
            if ((chainId && chainId.length !== 1) || !chainId) {
                groupByValue = `${groupByData[groupBy.fieldId]} (${groupByData['chain.name']} - ${groupByData['transaction.storeCode']})`;
            } else {
                groupByValue = `${groupByData[groupBy.fieldId]} (${groupByData['transaction.storeCode']})`;
            }
        }

        return _.truncate(groupByValue, { length: 15 });
    };

    const axisLeft = value => {
        return humanize(moment.duration(value, 'seconds').asMilliseconds(), {
            shortFormat: true,
            language: locale,
            largest: 2
        });
    };

    const label = ({ data, value }) => {
        let percentage = _.round(value / (data.totalTime ? data.totalTime : 1) * 100, 4);
        return `${percentage.toLocaleString(locale)} %`;
    };

    const tooltip = ({ data, value, color, id }) => {
        let {
            totalTime, [groupBy.fieldId]: groupByData, 'terminalType.name': terminalTypeName,
            'operator.name': operatorName, 'chain.name': chainName, 'transaction.storeCode': storeCode, unknown
        } = data;
        let percentage = _.round(value / (totalTime ? totalTime : 1) * 100, 4).toLocaleString(locale);
        let duration = humanize(moment.duration(value, 'seconds').asMilliseconds(), {
            shortFormat: false,
            language: locale,
            largest: 2
        });

        return (
            <div
                style={{
                    whiteSpace: 'pre',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span
                    style={{
                        display: 'block',
                        width: '12px',
                        height: '12px',
                        background: color,
                        marginRight: '7px'
                    }}
                > </span>
                <div style={{ display: 'grid' }}>
                    <span>
                        <strong>{id}</strong>
                    </span>
                    {(groupBy.id === 'terminalType.id' && !unknown) &&
                        <span>
                            {translate(groupBy.fieldDataTranslate)}: <strong>{terminalTypeName}</strong>
                        </span>
                    }
                    {(groupBy.id === 'terminalType.id' && unknown) &&
                        <span>
                            {translate(groupBy.fieldDataTranslate)}: <strong>{translate('resources.transactions.fields.withoutTerminalType')}</strong>
                        </span>
                    }
                    {(groupBy.id === 'transaction.operatorCode' && !unknown) &&
                        <span>
                            {translate(groupBy.fieldDataTranslate)}: <strong>{operatorName}</strong>
                        </span>
                    }
                    {!['terminalType.id', 'chain.id'].includes(groupBy.id) &&
                        <span>
                            {translate(groupBy.altName)}: <strong>{groupByData}</strong>
                        </span>
                    }
                    {['terminalType.id', 'transaction.operatorCode', 'chain.id'].includes(groupBy.id) &&
                        <span>
                            {translate('resources.chains.name', 1)}: <strong>{chainName}</strong>
                        </span>
                    }
                    {['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) &&
                        <span>
                            {translate('resources.transactions.fields.storeCode')}: <strong>{storeCode}</strong>
                        </span>
                    }
                    <span>
                        {translate('pos.datetime.timeName')}: <strong>{duration} {_.lowerFirst(translate('pos.generic.approximatelyAbbr'))}</strong>
                    </span>
                    <span>
                        {translate('pos.generic.percentage')}: <strong>{percentage} %</strong>
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className={classes.chart}>
            <ResponsiveBar
                data={processedData}
                label={label}
                tooltip={tooltip}
                keys={timeTypesTranslated.map(e => e.name)}
                indexBy="id"
                margin={{
                    top: 20,
                    bottom: [
                        'terminalType.id', 'transaction.operatorCode', 'transaction.storeCode',
                        'transaction.storeCodeAndDatetime', 'chain.id'
                    ].includes(groupBy.id) ? 120 : 100,
                    right: 200,
                    left: 100
                }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'paired' }}
                borderColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    format: axisBottom,
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: translate(groupBy.altName),
                    legendPosition: 'middle',
                    legendOffset: [
                        'terminalType.id', 'transaction.operatorCode', 'transaction.storeCode',
                        'transaction.storeCodeAndDatetime', 'chain.id'
                    ].includes(groupBy.id) ? 90 : 70
                }}
                axisLeft={{
                    format: axisLeft,
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: translate('pos.datetime.timeName'),
                    legendPosition: 'middle',
                    legendOffset: -80
                }}
                labelSkipWidth={48}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        itemDirection: 'left-to-right',
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        symbolSize: 14,
                    }
                ]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
            />
        </div>
    );
};

const TableToolbar = ({ data, filters, rowIdKey, headCells, title, numSelected, onSelectAllClick, order, orderBy, selected }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const dataProvider = useDataProvider();

    const [loading, setLoading] = useState(false);

    let { groupById, timeLimit } = filters;

    const groupBy = useMemo(() => {
        return groupByChoices.find(e => e.id === groupById);
    }, [groupById]);

    const getData = (page, perPage = 100, mainField) => {
        return dataProvider.getList('transactions', {
            pagination: {
                page: page,
                perPage: perPage
            },
            ...(mainField !== 'chainId' && {
                sort: {
                    field: mainField,
                    order: 'ASC'
                }
            }),
            filter: {
                include: 1,
                type: '00',
                ...(numSelected > 0 && { [mainField]: selected }),
                ...(filters.terminalNumber && { terminalNumber: filters.terminalNumber }),
                ...(filters.operatorCode && { operatorCode: filters.operatorCode }),
                ...(filters.chainId && { chainId: filters.chainId }),
                ...(filters.storeCode && { storeCode: filters.storeCode }),
                ...(filters.businessDayDate && (filters.businessDayDate.$gte || filters.businessDayDate.$lte) && {
                    businessDayDate: {
                        ...(filters.businessDayDate.$gte && { $gte: filters.businessDayDate.$gte }),
                        ...(filters.businessDayDate.$lte && { $lte: filters.businessDayDate.$lte }),
                    }
                })
            },
        });
    };

    const handleClickExportExcel = () => {
        let dataToSave;
        if (numSelected > 0) {
            dataToSave = data.data.filter(e => selected.includes(e[rowIdKey])).map(e => ({ ...e }));
        } else {
            dataToSave = data.data.map(e => ({ ...e }));
        }
        dataToSave = _.orderBy(dataToSave, [orderBy], [order]);

        const filteredHeadCells = _.sortBy(headCells.filter(e => !e.noExport), 'sort');
        const header = filteredHeadCells.map(e => e.id);
        let customHeader = filteredHeadCells.map(e => ({ [e.id]: e.label }));
        customHeader = customHeader.reduce((obj, item) => Object.assign(obj, { ...item }), {});

        dataToSave = dataToSave.map(e => {
            for (let key in e) {
                if (header.includes(key)) {
                    let headCell = filteredHeadCells.find(e => e.id === key);
                    if (headCell.formatter) {
                        e[key] = headCell.formatter(e[key]);
                    }
                    if (key === 'secondsPerItemLine') {
                        let color;
                        if (e[key] < timeLimit) {
                            color = '00B050'; // green
                        } else if (e[key] >= timeLimit && e[key] <= timeLimit + 2) {
                            color = 'FFFF00'; // yellow
                        } else {
                            color = 'FFC000'; // orange
                        }

                        e[key] = {
                            v: e[key],
                            t: 'n',
                            s: {
                                fill: {
                                    fgColor: { rgb: color },
                                },
                            }
                        };
                    }
                } else {
                    delete e[key];
                }
            }
            return e;
        });

        dataToSave.unshift(customHeader);

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const filename = title ? `${title}-data.xlsx` : 'data.xlsx';

        const worksheet = XLSX.utils.json_to_sheet(dataToSave, { header: header, skipHeader: true });
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excel = new Blob([excelBuffer], { type: fileType });
        saveAs(excel, filename);
    };

    const handleClickDetailExportCsv = () => {
        setLoading(true);
        const limit = 500;

        let mainField;
        switch (rowIdKey) {
            case 'transaction.terminalNumber':
                mainField = 'terminalNumber';
                break;
            case 'transaction.storeCode':
            case 'transaction.storeCodeAndDatetime':
                mainField = 'storeCode';
                break;
            case 'chain.id':
                mainField = 'chainId';
                break;
            case 'transaction.operatorCode':
            default:
                mainField = 'operatorCode';
        }

        let totalTransactions;
        if (numSelected > 0) {
            let filteredData = data.data.filter(e => selected.includes(e[rowIdKey]));
            totalTransactions = _.sumBy(filteredData, 'transactionQuantity');
        } else {
            totalTransactions = _.sumBy(data.data, 'transactionQuantity');
        }

        let totalPages = _.ceil(totalTransactions / limit);
        let getDataPromises = [];
        for (let i = 0; i < totalPages; i++) {
            getDataPromises.push(getData(i + 1, limit, mainField))
        }
        Promise.all(getDataPromises)
            .then(responses => {
                responses = _.sortBy(responses, 'skip');
                let data = responses.map(e => e.data).flat();
                data = data.map(e => {
                    let { startDateTime, endDateTime } = getStartAndEndDateTimeTransaction(e);
                    return {
                        ...e,
                        startDateTime: startDateTime.toISOString(),
                        endDateTime: endDateTime.toISOString(),
                        'operator.name': _.get(e, 'store.chain.operators[0].name'),
                        'chain.name': _.get(e, 'store.chain.name')
                    };
                });

                let csv = convertToCSV({
                    data: data,
                    fields: [
                        'storeCode', 'chain.name', 'operatorCode', 'operator.name', 'terminalNumber', 'sequenceNumber',
                        'businessDayDate', 'startDateTime', 'endDateTime', 'ringElapsedTime', 'tenderElapsedTime',
                        'specialElapsedTime', 'inactiveElapsedTime'
                    ]
                });
                const filename = title ? `${title}-data` : 'data';
                downloadCSV(csv, filename);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleClickDetailExportExcel = () => {
        setLoading(true);
        const limit = 500;

        let mainField;
        switch (rowIdKey) {
            case 'transaction.terminalNumber':
                mainField = 'terminalNumber';
                break;
            case 'transaction.storeCode':
            case 'transaction.storeCodeAndDatetime':
                mainField = 'storeCode';
                break;
            case 'chain.id':
                mainField = 'chainId';
                break;
            case 'transaction.operatorCode':
            default:
                mainField = 'operatorCode';
        }

        let totalTransactions;
        if (numSelected > 0) {
            let filteredData = data.data.filter(e => selected.includes(e[rowIdKey]));
            totalTransactions = _.sumBy(filteredData, 'transactionQuantity');
        } else {
            totalTransactions = _.sumBy(data.data, 'transactionQuantity');
        }

        let totalPages = _.ceil(totalTransactions / limit);
        let getDataPromises = [];
        for (let i = 0; i < totalPages; i++) {
            getDataPromises.push(getData(i + 1, limit, mainField))
        }
        Promise.all(getDataPromises)
            .then(responses => {
                responses = _.sortBy(responses, 'skip');
                let dataToSave = responses.map(e => e.data).flat();
                dataToSave = dataToSave.map(e => {
                    let { startDateTime, endDateTime } = getStartAndEndDateTimeTransaction(e);
                    return {
                        ...e,
                        startDateTime: startDateTime.toISOString(),
                        endDateTime: endDateTime.toISOString(),
                        'operator.name': _.get(e, 'store.chain.operators[0].name'),
                        'chain.name': _.get(e, 'store.chain.name')
                    };
                });

                const sortDetailHeadCells = _.sortBy(getDetailHeadCells(translate, locale), 'sort');
                const header = sortDetailHeadCells.map(e => e.id);
                let customHeader = sortDetailHeadCells.map(e => ({ [e.id]: e.label }));
                customHeader = customHeader.reduce((obj, item) => Object.assign(obj, { ...item }), {});

                dataToSave = dataToSave.map(e => {
                    for (let key in e) {
                        if (header.includes(key)) {
                            let headCell = sortDetailHeadCells.find(e => e.id === key);
                            if (headCell.formatter) {
                                e[key] = headCell.formatter(e[key]);
                            }
                        } else {
                            delete e[key];
                        }
                    }
                    return e;
                });

                dataToSave.unshift(customHeader);

                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const filename = title ? `${title}-data.xlsx` : 'data.xlsx';

                const worksheet = XLSX.utils.json_to_sheet(dataToSave, { header: header, skipHeader: true });
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                const excel = new Blob([excelBuffer], { type: fileType });
                saveAs(excel, filename);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <CustomTableToolbar
            data={data}
            filters={filters}
            rowIdKey={rowIdKey}
            headCells={headCells}
            title={title}
            numSelected={numSelected}
            onSelectAllClick={onSelectAllClick}
            order={order}
            orderBy={orderBy}
            selected={selected}
            menuButtons={true}
            exportExcelComponent={
                <MenuItem
                    onClick={handleClickExportExcel}
                    disabled={data.data.length === 0 || loading}
                    key="export-all-selected-xlsx"
                >
                    {numSelected === 0 ?
                        translate('pos.generic.exportAllAs', { as: 'Excel' }) :
                        translate('pos.generic.exportSelectedAs', { as: 'Excel' })
                    }
                </MenuItem>
            }
        >
            {groupBy.id !== 'terminalType.id' ? () => (
                [
                    (
                        <MenuItem
                            onClick={handleClickDetailExportCsv}
                            disabled={data.data.length === 0 || loading}
                            key="export-detail-all-selected-csv"
                        >
                            {numSelected === 0 ?
                                translate('pos.generic.exportDetailAllAs', { as: 'CSV' }) :
                                translate('pos.generic.exportDetailSelectedAs', { as: 'CSV' })
                            }
                        </MenuItem>
                    ),
                    (
                        <MenuItem
                            onClick={handleClickDetailExportExcel}
                            disabled={data.data.length === 0 || loading}
                            key="export-detail-all-selected-xlsx"
                        >
                            {numSelected === 0 ?
                                translate('pos.generic.exportDetailAllAs', { as: 'Excel' }) :
                                translate('pos.generic.exportDetailSelectedAs', { as: 'Excel' })
                            }
                        </MenuItem>
                    )
                ]
            ) : () => ([])}
        </CustomTableToolbar>
    );
};

const StartTransactionDateTimeField = ({ record, ...props }) => {
    const translate = useTranslate();

    if (!record || !record.businessDayDate) {
        return null;
    }

    let { startDateTime } = getStartAndEndDateTimeTransaction(record);
    const newRecord = {
        startDateTime: startDateTime.format('YYYY-MM-DD HH:mm:ss')
    };

    return (
        <CustomDateTimeField
            record={newRecord}
            source="startDateTime"
            showFormat={translate('pos.datetime.datetime')}
            {...props}
        />
    );
};

const EndTransactionDateTimeField = ({ record, ...props }) => {
    const translate = useTranslate();

    if (!record || !record.businessDayDate) {
        return null;
    }

    let { endDateTime } = getStartAndEndDateTimeTransaction(record);
    const newRecord = {
        endDateTime: endDateTime.format('YYYY-MM-DD HH:mm:ss')
    };

    return (
        <CustomDateTimeField
            record={newRecord}
            source="endDateTime"
            showFormat={translate('pos.datetime.datetime')}
            {...props}
        />
    );
};

const processData = ({ translate, data, loading, cancel, groupBy, precision, setProcessingData, setProcessedData }) => {
    setProcessingData(true);
    if (data && data.length > 0 && !loading && !cancel) {
        if (!['transaction.storeCode', 'transaction.storeCodeAndDatetime'].includes(groupBy.id)) {
            let processedData = {
                data: data.map(e => {
                    let obj = {
                        [groupBy.fieldId]: e[groupBy.fieldId],
                        [groupBy.fieldDataId]: e[groupBy.fieldDataId],
                        transactionQuantity: e.transactionQuantity,
                        itemLineQuantity: e.itemLineQuantity,
                        secondsPerItemLine: e.secondsPerItemLine,
                        transactionsPerHour: e.transactionsPerHour,
                        totalTime: 0,
                        ...(['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) && {
                            'chain.id': e['chain.id'],
                            'chain.name': e['chain.name'],
                            'transaction.storeCode': e['transaction.storeCode'],
                            id: getKey(groupBy)(e)
                        })
                    };

                    timeTypes.forEach(timeType => {
                        if (timeType.id in e) {
                            obj[timeType.id] = e[timeType.id];
                            obj.totalTime += e[timeType.id];
                            // obj[`total-${timeType.type}`] = obj[`total-${timeType.type}`] ?
                            //    obj[`total-${timeType.type}`] + e[timeType.id] : e[timeType.id];
                        }
                    });

                    return obj;
                })
            };

            processedData.totalTotalTime = _.sumBy(processedData.data, 'totalTime');
            timeTypes.map(e => e.id).forEach(timeType => {
                processedData[`total-${timeType}`] = _.sumBy(processedData.data, timeType);
            });
            processedData.totalTransactionQuantity = _.sumBy(processedData.data, 'transactionQuantity');
            processedData.totalItemLineQuantity = _.sumBy(processedData.data, 'itemLineQuantity');

            processedData.data = _.orderBy(processedData.data, ['secondsPerItemLine'], ['asc'])

            setProcessedData(processedData);
        } else if (groupBy.id === 'transaction.storeCodeAndDatetime') {
            let processedData = {
                data: []
            };

            let groupByValues = data.map(e => ({
                key: e[groupBy.fieldId],
                [groupBy.fieldId]: e[groupBy.fieldId]
            }));
            groupByValues = Object.fromEntries(groupByValues.map(e => [e.key, e]));

            Object.entries(groupByValues).forEach(([groupByValue, value]) => {
                let filteredData1 = data.filter(e => e[groupBy.fieldId] === groupByValue);
                let groupByValues1 = filteredData1.map(e => ({
                    key: e.datetime,
                    datetime: dateFormatter(translate)(precision, e.datetime),
                    originalDatetime: e.datetime
                }));
                groupByValues1 = Object.fromEntries(groupByValues1.map(e => [e.key, e]));

                let groupByData1 = {
                    ...value,
                    id: groupByValue,
                    [groupBy.fieldId]: groupByValue,
                    transactionQuantity: _.sumBy(filteredData1, 'transactionQuantity'),
                    itemLineQuantity: _.sumBy(filteredData1, 'itemLineQuantity'),
                    totalTime: 0,
                    datetimes: []
                };

                Object.entries(groupByValues1).forEach(([groupByValue1, value1]) => {
                    let filteredData2 = filteredData1.filter(e => e.datetime === groupByValue1);
                    let groupByData2 = {
                        ...value1,
                        ...value,
                        id: groupByValue1,
                        transactionQuantity: _.sumBy(filteredData2, 'transactionQuantity'),
                        itemLineQuantity: _.sumBy(filteredData2, 'itemLineQuantity'),
                        totalTime: 0,
                        operators: filteredData2.map(e => {
                            let obj = {
                                ...value1,
                                ...value,
                                transactionQuantity: e.transactionQuantity,
                                itemLineQuantity: e.itemLineQuantity,
                                secondsPerItemLine: e.secondsPerItemLine,
                                totalTime: 0,
                                [groupBy.fieldId]: groupByValue,
                                'operator.name': e['operator.name'],
                                'chain.name': e['chain.name'],
                                'transaction.operatorCode': e['transaction.operatorCode']
                            };

                            timeTypes.forEach(timeType => {
                                if (timeType.id in e) {
                                    obj[timeType.id] = e[timeType.id];
                                    obj.totalTime += e[timeType.id];
                                    obj[`total-${timeType.type}`] = obj[`total-${timeType.type}`] ?
                                        obj[`total-${timeType.type}`] + e[timeType.id] : e[timeType.id];
                                }
                            });

                            let totalTransactionTimeAsHours = moment.duration(obj['total-transactionTime'], 'seconds').asHours();
                            if (totalTransactionTimeAsHours) {
                                obj.transactionsPerHour = _.round(obj.transactionQuantity / totalTransactionTimeAsHours, 4);
                            } else {
                                obj.transactionsPerHour = null;
                            }

                            return obj;
                        })
                    };

                    timeTypes.forEach(timeType => {
                        let totalTime = _.sumBy(groupByData2.operators, timeType.id);
                        groupByData2[timeType.id] = totalTime;
                        groupByData2.totalTime += totalTime;
                        groupByData2[`total-${timeType.type}`] = groupByData2[`total-${timeType.type}`] ?
                            groupByData2[`total-${timeType.type}`] + totalTime : totalTime;
                    });

                    groupByData2.secondsPerItemLine = groupByData2.itemLineQuantity === 0 ?
                        null : groupByData2.ringElapsedTime / groupByData2.itemLineQuantity;

                    let totalTransactionTimeAsHours = moment.duration(groupByData2['total-transactionTime'], 'seconds').asHours();
                    if (totalTransactionTimeAsHours) {
                        groupByData2.transactionsPerHour = _.round(groupByData2.transactionQuantity / totalTransactionTimeAsHours, 4);
                    } else {
                        groupByData2.transactionsPerHour = null;
                    }

                    groupByData1.datetimes.push(groupByData2);
                });

                timeTypes.forEach(timeType => {
                    let totalTime = _.sumBy(groupByData1.datetimes, timeType.id);
                    groupByData1[timeType.id] = totalTime;
                    groupByData1.totalTime += totalTime;
                    groupByData1[`total-${timeType.type}`] = groupByData1[`total-${timeType.type}`] ?
                        groupByData1[`total-${timeType.type}`] + totalTime : totalTime;
                });

                groupByData1.secondsPerItemLine = groupByData1.itemLineQuantity === 0 ?
                    null : groupByData1.ringElapsedTime / groupByData1.itemLineQuantity;

                let totalTransactionTimeAsHours = moment.duration(groupByData1['total-transactionTime'], 'seconds').asHours();
                if (totalTransactionTimeAsHours) {
                    groupByData1.transactionsPerHour = _.round(groupByData1.transactionQuantity / totalTransactionTimeAsHours, 4);
                } else {
                    groupByData1.transactionsPerHour = null;
                }

                processedData.data.push(groupByData1);
            });

            processedData.data = _.orderBy(processedData.data, ['secondsPerItemLine'], ['asc']);

            processedData.totalTotalTime = _.sumBy(processedData.data, 'totalTime');
            timeTypes.map(e => e.id).forEach(timeType => {
                processedData[`total-${timeType}`] = _.sumBy(processedData.data, timeType);
            });
            processedData.totalTransactionQuantity = _.sumBy(processedData.data, 'transactionQuantity');
            processedData.totalItemLineQuantity = _.sumBy(processedData.data, 'itemLineQuantity');

            setProcessedData(processedData);
        } else if (groupBy.id === 'transaction.storeCode') {
            let processedData = {
                data: []
            };

            const groupByValues = _.uniq(data.map(e => e[groupBy.fieldId]));
            groupByValues.forEach(groupByValue => {
                let filteredData = data.filter(e => e[groupBy.fieldId] === groupByValue);
                let groupByData = {
                    [groupBy.fieldId]: groupByValue,
                    transactionQuantity: _.sumBy(filteredData, 'transactionQuantity'),
                    itemLineQuantity: _.sumBy(filteredData, 'itemLineQuantity'),
                    totalTime: 0,
                    operators: filteredData.map(e => {
                        let obj = {
                            transactionQuantity: e.transactionQuantity,
                            itemLineQuantity: e.itemLineQuantity,
                            secondsPerItemLine: e.secondsPerItemLine,
                            totalTime: 0,
                            [groupBy.fieldId]: groupByValue,
                            'operator.name': e['operator.name'],
                            'chain.name': e['chain.name'],
                            'transaction.operatorCode': e['transaction.operatorCode']
                        };

                        timeTypes.forEach(timeType => {
                            if (timeType.id in e) {
                                obj[timeType.id] = e[timeType.id];
                                obj.totalTime += e[timeType.id];
                                obj[`total-${timeType.type}`] = obj[`total-${timeType.type}`] ?
                                    obj[`total-${timeType.type}`] + e[timeType.id] : e[timeType.id];
                            }
                        });

                        let totalTransactionTimeAsHours = moment.duration(obj['total-transactionTime'], 'seconds').asHours();
                        if (totalTransactionTimeAsHours) {
                            obj.transactionsPerHour = _.round(obj.transactionQuantity / totalTransactionTimeAsHours, 4);
                        } else {
                            obj.transactionsPerHour = null;
                        }

                        return obj;
                    })
                };

                timeTypes.forEach(timeType => {
                    let totalTime = _.sumBy(groupByData.operators, timeType.id);
                    groupByData[timeType.id] = totalTime;
                        groupByData.totalTime += totalTime;
                        groupByData[`total-${timeType.type}`] = groupByData[`total-${timeType.type}`] ?
                            groupByData[`total-${timeType.type}`] + totalTime : totalTime;
                });

                groupByData.secondsPerItemLine = groupByData.itemLineQuantity === 0 ?
                    null : groupByData.ringElapsedTime / groupByData.itemLineQuantity;

                let totalTransactionTimeAsHours = moment.duration(groupByData['total-transactionTime'], 'seconds').asHours();
                if (totalTransactionTimeAsHours) {
                    groupByData.transactionsPerHour = _.round(groupByData.transactionQuantity / totalTransactionTimeAsHours, 4);
                } else {
                    groupByData.transactionsPerHour = 0;
                }

                groupByData.operators = _.orderBy(groupByData.operators, ['secondsPerItemLine'], ['asc']);
                processedData.data.push(groupByData);
            });

            processedData.totalTotalTime = _.sumBy(processedData.data, 'totalTime');
            timeTypes.map(e => e.id).forEach(timeType => {
                processedData[`total-${timeType}`] = _.sumBy(processedData.data, timeType);
            });
            processedData.totalTransactionQuantity = _.sumBy(processedData.data, 'transactionQuantity');
            processedData.totalItemLineQuantity = _.sumBy(processedData.data, 'itemLineQuantity');

            setProcessedData(processedData);
        }

        setProcessingData(false);
    }
};

const getBusinessDayDateRange = ({ datetime, precision, businessDayDate }) => {
    let gte = moment(datetime).startOf(precision);
    let lte = moment(datetime).endOf(precision).set('second', 0);

    if (businessDayDate.$gte && gte.isBefore(moment(businessDayDate.$gte))) {
        gte = moment(businessDayDate.$gte);
    }

    if (businessDayDate.$lte && lte.isAfter(moment(businessDayDate.$lte))) {
        lte = moment(businessDayDate.$lte);
    }

    return {
        $gte: gte.format('YYYY-MM-DD HH:mm:ss'),
        $lte: lte.format('YYYY-MM-DD HH:mm:00')
    };
};

const showDetails = ({ row, filters, setTransactionFilter, setDefaultFilter, setRow, setOpenModal }) => {
    if (!row) {
        return;
    }

    let { groupById, precision, ignoreZeroTimes } = filters;
    const groupBy = groupByChoices.find(e => e.id === groupById);

    let mainField;
    switch (groupBy.id) {
        case 'transaction.terminalNumber':
            mainField = 'terminalNumber';
            break;
        case 'transaction.storeCode':
        case 'transaction.storeCodeAndDatetime':
            mainField = 'storeCode';
            break;
        case 'chain.id':
            mainField = 'chainId';
            break;
        case 'transaction.operatorCode':
        default:
            mainField = 'operatorCode';
    }

    setTransactionFilter({
        include: 1,
        type: ['00'],
        ignoreZeroTimes: ignoreZeroTimes ? 1: 0,
        ...(row && {
            [mainField]: mainField === 'storeCode' ? [row[groupBy.fieldId]] : row[groupBy.fieldId]
        }),
        ...(filters.chainId && { chainId: filters.chainId }),
        ...(groupBy.id === 'transaction.operatorCode' ?
                { storeCode: [row['transaction.storeCode']] } : filters.storeCode && { storeCode: filters.storeCode }
        ),
        ...((['transaction.storeCode', 'transaction.storeCodeAndDatetime'].includes(groupBy.id) && row['transaction.operatorCode']) ?
                { operatorCode: row['transaction.operatorCode'] } : filters.operatorCode && { operatorCode: filters.operatorCode }
        ),
        ...((['transaction.storeCode', 'transaction.storeCodeAndDatetime'].includes(groupBy.id) && row.originalDatetime && precision) ? {
            businessDayDate: getBusinessDayDateRange({
                datetime: row.originalDatetime,
                precision: precision,
                businessDayDate: filters.businessDayDate
            })
        } : (filters.businessDayDate && (filters.businessDayDate.$gte || filters.businessDayDate.$lte)) && {
            businessDayDate: {
                ...(filters.businessDayDate.$gte && { $gte: filters.businessDayDate.$gte }),
                ...(filters.businessDayDate.$lte && { $lte: filters.businessDayDate.$lte }),
            }
        })
    });
    setDefaultFilter({
        type: ['00'],
        ignoreZeroTimes: ignoreZeroTimes ? 1: 0,
        ...(row && {
            [mainField]: mainField === 'storeCode' ? [row[groupBy.fieldId]] : row[groupBy.fieldId]
        }),
        ...(groupBy.id === 'transaction.operatorCode' ?
                { storeCode: [row['transaction.storeCode']] } : filters.storeCode && { storeCode: filters.storeCode }
        ),
        ...((['transaction.storeCode', 'transaction.storeCodeAndDatetime'].includes(groupBy.id) && row['transaction.operatorCode']) ?
                { operatorCode: row['transaction.operatorCode'] } : filters.operatorCode && { operatorCode: filters.operatorCode }
        ),
        ...((['transaction.storeCode', 'transaction.storeCodeAndDatetime'].includes(groupBy.id) && row.originalDatetime && precision) ? {
            businessDayDate: getBusinessDayDateRange({
                datetime: row.originalDatetime,
                precision: precision,
                businessDayDate: filters.businessDayDate
            })
        } : (filters.businessDayDate && (filters.businessDayDate.$gte || filters.businessDayDate.$lte)) && {
            businessDayDate: {
                ...(filters.businessDayDate.$gte && { $gte: filters.businessDayDate.$gte }),
                ...(filters.businessDayDate.$lte && { $lte: filters.businessDayDate.$lte }),
            }
        })
    });
    setRow(row);
    setOpenModal(true);
};

const TransactionDialog = ({ row, openModal, handleCloseModal, transactionFilter, defaultFilter, filters }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');

    let { groupById } = filters;

    const groupBy = useMemo(() => {
        return groupByChoices.find(e => e.id === groupById);
    }, [groupById]);

    const getTitle = row => {
        let title = `${translate('pos.generic.detail')} - `;
        if (groupBy.id === 'transaction.operatorCode') {
            if (row[groupBy.fieldDataId]) {
                title += `${translate(groupBy.fieldDataTranslate)}: ` +
                    `${row[groupBy.fieldDataId]} (${row[groupBy.fieldId]} - ${row['chain.name']})`;
            } else {
                title += `${translate(groupBy.name)}: ${row[groupBy.fieldId]} (${row['chain.name']})`;
            }
        } else if (['transaction.storeCode', 'transaction.storeCodeAndDatetime'].includes(groupBy.id) && row['transaction.operatorCode']) {
            if (row['operator.name']) {
                title += `${translate('resources.transactions.fields.operatorName')}: ` +
                    `${row['operator.name']} (${row['transaction.operatorCode']} - ${row[groupBy.fieldId]} - ${row['chain.name']})`;
            } else {
                title += `${row['transaction.operatorCode']} (${row[groupBy.fieldId]} - ${row['chain.name']})`;
            }
        } else if (groupBy.id === 'chain.id') {
            title += `${translate(groupBy.fieldDataTranslate, 1)}: ${row[groupBy.fieldDataId]}`;
        } else {
            if (groupBy.id === 'transaction.storeCodeAndDatetime') {
                title += `${translate(groupBy.altName)}: ${row[groupBy.fieldId]}`;
            } else {
                title += `${translate(groupBy.altName)}: ${row[groupBy.fieldId]}`;
            }
        }
        return title;
    };

    const exporter = transactions => {
        let dataToSave = transactions.map(e => ({ ...e }));
        dataToSave = dataToSave.map(e => {
            let { startDateTime, endDateTime } = getStartAndEndDateTimeTransaction(e);
            return {
                ...e,
                startDateTime: startDateTime.toISOString(),
                endDateTime: endDateTime.toISOString(),
                'operator.name': _.get(e, 'store.chain.operators[0].name'),
                'chain.name': _.get(e, 'store.chain.name')
            };
        });

        const sortDetailHeadCells = _.sortBy(getDetailHeadCells(translate, locale), 'sort');
        const header = sortDetailHeadCells.map(e => e.id);
        let customHeader = sortDetailHeadCells.map(e => ({ [e.id]: e.label }));
        customHeader = customHeader.reduce((obj, item) => Object.assign(obj, { ...item }), {});

        dataToSave = dataToSave.map(e => {
            for (let key in e) {
                if (header.includes(key)) {
                    let headCell = sortDetailHeadCells.find(e => e.id === key);
                    if (headCell.formatter) {
                        e[key] = headCell.formatter(e[key]);
                    }
                } else {
                    delete e[key];
                }
            }
            return e;
        });

        dataToSave.unshift(customHeader);

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const filename = 'transactions.xlsx';

        const worksheet = XLSX.utils.json_to_sheet(dataToSave, { header: header, skipHeader: true });
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excel = new Blob([excelBuffer], { type: fileType });
        saveAs(excel, filename);
    };

    return (
        <CustomDialog title={getTitle(row)} open={openModal} onClose={handleCloseModal}>
            <ReferenceManyField
                reference="transactions"
                target="storeCode"
                addLabel={false}
                perPage={25}
                filter={transactionFilter}
                filterDefaultValues={defaultFilter}
                disabledFilters={{
                    storeCode: ['transaction.operatorCode', 'transaction.storeCode', 'transaction.storeCodeAndDatetime'].includes(groupBy.id) ||
                        (filters.storeCode && filters.storeCode.length > 0),
                    terminalNumber: groupBy.id === 'transaction.terminalNumber',
                    operatorCode: groupBy.id === 'transaction.operatorCode' || row['transaction.operatorCode'],
                    type: true,
                    businessDayDate: {
                        $gte: filters.businessDayDate && filters.businessDayDate.$gte,
                        $lte: filters.businessDayDate && filters.businessDayDate.$lte
                    }
                }}
            >
                <TransactionList voidTitle={true} exporter={exporter}>
                    {({ loading }) => {
                        if (loading) {
                            return (
                                <div className={classes.loadingTable}>
                                    <CircularProgress/>
                                </div>
                            );
                        }

                        return (
                            <Datagrid classes={{ headerCell: classes.headerCell }}>
                                <CustomReferenceField
                                    originalSource="storeCode"
                                    injectSource="storeId"
                                    reference="stores"
                                    label="resources.transactions.fields.storeCode"
                                />
                                {groupBy.id === 'transaction.terminalNumber' ? (
                                    <TextField source="operatorCode" />
                                ) : (
                                    <NumberField source="terminalNumber" locales={locale} />
                                )}
                                <NumberField source="sequenceNumber" locales={locale} />
                                <StartTransactionDateTimeField label="resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.startTransaction" />
                                <EndTransactionDateTimeField label="resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.endTransaction" />
                                <DurationField source="ringElapsedTime" emptyText={emptyText} shortFormat />
                                <DurationField source="tenderElapsedTime" emptyText={emptyText} shortFormat />
                                <DurationField source="specialElapsedTime" emptyText={emptyText} shortFormat />
                                <DurationField source="inactiveElapsedTime" emptyText={emptyText} shortFormat />
                                <TransactionShowButton target="_blank" rel="noopener noreferrer" />
                            </Datagrid>
                        );
                    }}
                </TransactionList>
            </ReferenceManyField>
        </CustomDialog>
    );
};

const StoreRow = ({ 'aria-checked': ariaChecked, data, filters, labelId, tableCellClass, checkbox, detailButton }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');

    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [transactionFilter, setTransactionFilter] = useState();
    const [defaultFilter, setDefaultFilter] = useState();
    const [datetimeRow, setDatetimeRow] = useState();

    const handleClickShow = (event, row) => {
        showDetails({ row, filters, setDefaultFilter, setTransactionFilter, setRow: setDatetimeRow, setOpenModal });
    };

    const handleCloseModal = () => {
        setTransactionFilter();
        setDatetimeRow();
        setOpenModal(false);
    };

    return (
        <React.Fragment>
            <TableRow
                aria-checked={ariaChecked}
                role="checkbox"
                tabIndex={-1}
                hover
            >
                {checkbox}
                <TableCell className={tableCellClass}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" id={labelId} scope="row" padding="none" className={tableCellClass}>
                    <TextField record={data} source="transaction.storeCode" />
                </TableCell>
                <TableCell align="right" className={clsx(tableCellClass, classes.tableColumnHighlight)}>
                    <NumberField record={data} source="secondsPerItemLine" locales={locale} emptyText={emptyText} />
                </TableCell>
                {timeTypes.map(timeType => (
                    <TableCell key={timeType.id} align="right" className={tableCellClass}>
                        <DurationField record={data} source={timeType.id} shortFormat />
                    </TableCell>
                ))}
                <TableCell align="right" className={tableCellClass}>
                    <DurationField record={data} source="totalTime" shortFormat />
                </TableCell>
                <TableCell align="right" className={tableCellClass}>
                    <NumberField record={data} source="transactionQuantity" locales={locale} />
                </TableCell>
                <TableCell align="right" className={tableCellClass}>
                    <NumberField
                        record={data}
                        source="transactionsPerHour"
                        locales={locale}
                        emptyText={emptyText}
                    />
                </TableCell>
                <TableCell align="right" className={tableCellClass}>
                    <NumberField record={data} source="itemLineQuantity" locales={locale} />
                </TableCell>
                <TableCell className={tableCellClass}>
                    {detailButton}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={9 + timeTypes.length}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={tableCellClass} />
                                    <TableCell className={tableCellClass}>
                                        {translate('pos.datetime.dateName')}
                                    </TableCell>
                                    <TableCell align="right" className={tableCellClass}>
                                        {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.secondsPerItemLine')}
                                    </TableCell>
                                    {timeTypes.map(timeType => (
                                        <TableCell key={timeType.id} align="right" className={tableCellClass}>
                                            {translate(timeType.abbrName)}
                                        </TableCell>
                                    ))}
                                    <TableCell align="right" className={tableCellClass}>
                                        {translate('pos.datetime.totalTime')}
                                    </TableCell>
                                    <TableCell align="right" className={tableCellClass}>
                                        {translate('pos.generic.customers')}
                                    </TableCell>
                                    <TableCell align="right" className={tableCellClass}>
                                        {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.transactionsPerHour')}
                                    </TableCell>
                                    <TableCell align="right" className={tableCellClass}>
                                        {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.itemLineQuantity')}
                                    </TableCell>
                                    <TableCell className={tableCellClass} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.datetimes.map(datetime => (
                                    <DatetimeRow
                                        key={datetime.id}
                                        data={datetime}
                                        filters={filters}
                                        labelId={labelId}
                                        tableCellClass={tableCellClass}
                                        detailButton={
                                            <RaButton
                                                onClick={event => handleClickShow(event, datetime)}
                                                label="pos.generic.detail"
                                            >
                                                <VisibilityIcon />
                                            </RaButton>
                                        }
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
            {datetimeRow && transactionFilter &&
                <TransactionDialog
                    row={datetimeRow}
                    openModal={openModal}
                    handleCloseModal={handleCloseModal}
                    transactionFilter={transactionFilter}
                    defaultFilter={defaultFilter}
                    filters={filters}
                    exporter={null}
                />
            }
        </React.Fragment>
    );
};

const DatetimeRow = ({ data, filters, labelId, tableCellClass, detailButton }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');

    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [transactionFilter, setTransactionFilter] = useState();
    const [defaultFilter, setDefaultFilter] = useState();
    const [operatorRow, setOperatorRow] = useState();

    const handleClickShow = (event, row) => {
        showDetails({ row, filters, setDefaultFilter, setTransactionFilter, setRow: setOperatorRow, setOpenModal });
    };

    const handleCloseModal = () => {
        setTransactionFilter();
        setOperatorRow();
        setOpenModal(false);
    };

    return (
        <React.Fragment>
            <TableRow tabIndex={-1} hover>
                <TableCell className={tableCellClass}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" id={labelId} scope="row" padding="none" className={tableCellClass}>
                    <TextField record={data} source="datetime" />
                </TableCell>
                <TableCell align="right" className={clsx(tableCellClass, classes.tableColumnHighlight)}>
                    <NumberField record={data} source="secondsPerItemLine" locales={locale} emptyText={emptyText} />
                </TableCell>
                {timeTypes.map(timeType => (
                    <TableCell key={timeType.id} align="right" className={tableCellClass}>
                        <DurationField record={data} source={timeType.id} shortFormat />
                    </TableCell>
                ))}
                <TableCell align="right" className={tableCellClass}>
                    <DurationField record={data} source="totalTime" shortFormat />
                </TableCell>
                <TableCell align="right" className={tableCellClass}>
                    <NumberField record={data} source="transactionQuantity" locales={locale} />
                </TableCell>
                <TableCell align="right" className={tableCellClass}>
                    <NumberField
                        record={data}
                        source="transactionsPerHour"
                        locales={locale}
                        emptyText={emptyText}
                    />
                </TableCell>
                <TableCell align="right" className={tableCellClass}>
                    <NumberField record={data} source="itemLineQuantity" locales={locale} />
                </TableCell>
                <TableCell className={tableCellClass}>
                    {detailButton}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={9 + timeTypes.length}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={tableCellClass}>
                                        {translate('resources.transactions.fields.operatorCode')}
                                    </TableCell>
                                    <TableCell className={tableCellClass}>
                                        {translate('resources.transactions.fields.operatorName')}
                                    </TableCell>
                                    <TableCell align="right">
                                        {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.secondsPerItemLine')}
                                    </TableCell>
                                    {timeTypes.map(timeType => (
                                        <TableCell key={timeType.id} align="right" className={tableCellClass}>
                                            {translate(timeType.abbrName)}
                                        </TableCell>
                                    ))}
                                    <TableCell align="right" className={tableCellClass}>
                                        {translate('pos.datetime.totalTime')}
                                    </TableCell>
                                    <TableCell align="right" className={tableCellClass}>
                                        {translate('pos.generic.customers')}
                                    </TableCell>
                                    <TableCell align="right" className={tableCellClass}>
                                        {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.transactionsPerHour')}
                                    </TableCell>
                                    <TableCell align="right" className={tableCellClass}>
                                        {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.itemLineQuantity')}
                                    </TableCell>
                                    <TableCell className={tableCellClass} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.operators.map(operator => (
                                    <TableRow key={operator['transaction.operatorCode']}>
                                        <TableCell component="th" scope="row" className={tableCellClass}>
                                            <TextField record={operator} source="transaction.operatorCode" />
                                        </TableCell>
                                        <TableCell component="th" scope="row" className={tableCellClass}>
                                            <TextField record={operator} source="operator.name" emptyText={emptyText} />
                                        </TableCell>
                                        <TableCell align="right" className={tableCellClass}>
                                            <NumberField
                                                record={operator}
                                                source="secondsPerItemLine"
                                                locales={locale}
                                                emptyText={emptyText}
                                            />
                                        </TableCell>
                                        {timeTypes.map(timeType => (
                                            <TableCell key={timeType.id} align="right" className={tableCellClass}>
                                                <DurationField record={operator} source={timeType.id} shortFormat />
                                            </TableCell>
                                        ))}
                                        <TableCell align="right" className={tableCellClass}>
                                            <DurationField record={operator} source="totalTime" shortFormat />
                                        </TableCell>
                                        <TableCell align="right" className={tableCellClass}>
                                            <NumberField record={operator} source="transactionQuantity" locales={locale} />
                                        </TableCell>
                                        <TableCell align="right" className={tableCellClass}>
                                            <NumberField
                                                record={operator}
                                                source="transactionsPerHour"
                                                locales={locale}
                                                emptyText={emptyText}
                                            />
                                        </TableCell>
                                        <TableCell align="right" className={tableCellClass}>
                                            <NumberField record={operator} source="itemLineQuantity" locales={locale} />
                                        </TableCell>
                                        <TableCell className={tableCellClass}>
                                            <RaButton
                                                onClick={event => handleClickShow(event, operator)}
                                                label="pos.generic.detail"
                                            >
                                                <VisibilityIcon />
                                            </RaButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
            {operatorRow && transactionFilter &&
                <TransactionDialog
                    row={operatorRow}
                    openModal={openModal}
                    handleCloseModal={handleCloseModal}
                    transactionFilter={transactionFilter}
                    defaultFilter={defaultFilter}
                    filters={filters}
                    exporter={null}
                />
            }
        </React.Fragment>
    );
};

const Row = ({ 'aria-checked': ariaChecked, data, filters, labelId, tableCellClass, checkbox, detailButton }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');

    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [transactionFilter, setTransactionFilter] = useState();
    const [defaultFilter, setDefaultFilter] = useState();
    const [operatorRow, setOperatorRow] = useState();

    let { groupById } = filters;

    const groupBy = useMemo(() => {
        return groupByChoices.find(e => e.id === groupById);
    }, [groupById]);

    const handleClickShow = (event, row) => {
        showDetails({ row, filters, setDefaultFilter, setTransactionFilter, setRow: setOperatorRow, setOpenModal });
    };

    const handleCloseModal = () => {
        setTransactionFilter();
        setOperatorRow();
        setOpenModal(false);
    };

    return (
        <React.Fragment>
            <TableRow
                aria-checked={ariaChecked}
                role="checkbox"
                tabIndex={-1}
                hover
            >
                {checkbox}
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" id={labelId} scope="row" padding="none" className={tableCellClass}>
                    <TextField record={data} source={groupBy.fieldId} />
                </TableCell>
                <TableCell align="right" className={clsx(tableCellClass, classes.tableColumnHighlight)}>
                    <NumberField record={data} source="secondsPerItemLine" locales={locale} emptyText={emptyText} />
                </TableCell>
                {timeTypes.map(timeType => (
                    <TableCell key={timeType.id} align="right" className={tableCellClass}>
                        <DurationField record={data} source={timeType.id} shortFormat />
                    </TableCell>
                ))}
                <TableCell align="right" className={tableCellClass}>
                    <DurationField record={data} source="totalTime" shortFormat />
                </TableCell>
                <TableCell align="right" className={tableCellClass}>
                    <NumberField record={data} source="transactionQuantity" locales={locale} />
                </TableCell>
                <TableCell align="right" className={tableCellClass}>
                    <NumberField
                        record={data}
                        source="transactionsPerHour"
                        locales={locale}
                        emptyText={emptyText}
                    />
                </TableCell>
                <TableCell align="right" className={tableCellClass}>
                    <NumberField record={data} source="itemLineQuantity" locales={locale} />
                </TableCell>
                <TableCell>
                    {detailButton}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={9 + timeTypes.length}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {translate('resources.transactions.fields.operatorCode')}
                                    </TableCell>
                                    <TableCell>
                                        {translate('resources.transactions.fields.operatorName')}
                                    </TableCell>
                                    <TableCell align="right">
                                        {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.secondsPerItemLine')}
                                    </TableCell>
                                    {timeTypes.map(timeType => (
                                        <TableCell key={timeType.id} align="right">
                                            {translate(timeType.abbrName)}
                                        </TableCell>
                                    ))}
                                    <TableCell align="right">
                                        {translate('pos.datetime.totalTime')}
                                    </TableCell>
                                    <TableCell align="right">
                                        {translate('pos.generic.customers')}
                                    </TableCell>
                                    <TableCell align="right">
                                        {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.transactionsPerHour')}
                                    </TableCell>
                                    <TableCell align="right">
                                        {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.itemLineQuantity')}
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.operators.map(operator => (
                                    <TableRow key={operator['transaction.operatorCode']}>
                                        <TableCell component="th" scope="row">
                                            <TextField record={operator} source="transaction.operatorCode" />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <TextField record={operator} source="operator.name" emptyText={emptyText} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumberField
                                                record={operator}
                                                source="secondsPerItemLine"
                                                locales={locale}
                                                emptyText={emptyText}
                                            />
                                        </TableCell>
                                        {timeTypes.map(timeType => (
                                            <TableCell key={timeType.id} align="right" className={tableCellClass}>
                                                <DurationField record={operator} source={timeType.id} shortFormat />
                                            </TableCell>
                                        ))}
                                        <TableCell align="right">
                                            <DurationField record={operator} source="totalTime" shortFormat />
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumberField record={operator} source="transactionQuantity" locales={locale} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumberField
                                                record={operator}
                                                source="transactionsPerHour"
                                                locales={locale}
                                                emptyText={emptyText}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumberField record={operator} source="itemLineQuantity" locales={locale} />
                                        </TableCell>
                                        <TableCell>
                                            <RaButton
                                                onClick={event => handleClickShow(event, operator)}
                                                label="pos.generic.detail"
                                            >
                                                <VisibilityIcon />
                                            </RaButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
            {operatorRow && transactionFilter &&
                <TransactionDialog
                    row={operatorRow}
                    openModal={openModal}
                    handleCloseModal={handleCloseModal}
                    transactionFilter={transactionFilter}
                    defaultFilter={defaultFilter}
                    filters={filters}
                    exporter={null}
                />
            }
        </React.Fragment>
    );
};

const DataTable = ({ filters, data, loading, cancel, error }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [transactionFilter, setTransactionFilter] = useState();
    const [defaultFilter, setDefaultFilter] = useState();
    const [row, setRow] = useState();

    let { groupById, precision } = filters;

    const groupBy = useMemo(() => {
        return groupByChoices.find(e => e.id === groupById);
    }, [groupById]);
    
    useEffect(() => {
        processData({ translate, data, loading, cancel, groupBy, precision, setProcessingData, setProcessedData });
    }, [translate, data, loading, cancel, groupBy, precision]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const getHeadCells = () => {
        if (['transaction.storeCode', 'transaction.storeCodeAndDatetime'].includes(groupBy.id)) {
            let headCells = [
                {
                    sort: 1,
                    id: 'button',
                    label: false,
                    align: 'left',
                    noExport: true
                },
                {
                    sort: 2,
                    id: groupBy.fieldId,
                    label: translate(groupBy.altName),
                    align: 'left'
                },
                {
                    sort: 3,
                    id: 'secondsPerItemLine',
                    label:  translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.secondsPerItemLine'),
                    align: 'right',
                    classes: classes.tableColumnHighlight
                },
                {
                    sort: timeTypes.length + 4,
                    id: 'totalTime',
                    label: translate('pos.datetime.totalTime'),
                    align: 'right',
                    formatter: humanizeTime(locale)
                },
                {
                    sort: timeTypes.length + 5,
                    id: 'transactionQuantity',
                    label: translate('pos.generic.customers'),
                    align: 'right'
                },
                {
                    sort: timeTypes.length + 6,
                    id: 'transactionsPerHour',
                    label: translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.transactionsPerHour'),
                    align: 'right'
                },
                {
                    sort: timeTypes.length + 7,
                    id: 'itemLineQuantity',
                    label: translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.itemLineQuantity'),
                    align: 'right'
                },
                {
                    sort: timeTypes.length + 8,
                    id: 'options',
                    label: false,
                    align: 'left',
                    noExport: true
                }
            ];
            headCells = headCells.concat(timeTypes.map((timeType, index) => ({
                sort: index + 4,
                id: timeType.id,
                label: translate(timeType.abbrName),
                align: 'right',
                formatter: humanizeTime(locale)
            })));

            return headCells;
        }

        let offset = 0;
        if (groupBy.id === 'terminalType.id') {
            offset = 1;
        } else if (groupBy.id === 'transaction.operatorCode') {
            offset = 2;
        }

        let headCells = [
            ...(!['terminalType.id', 'chain.id'].includes(groupBy.id) ? [{
                sort: 1,
                id: groupBy.fieldId,
                label: translate(groupBy.altName),
                align: 'left'
            }] : [{
                sort: 1,
                id: groupBy.fieldDataId,
                label: translate(groupBy.fieldDataTranslate, 1),
                align: 'left'
            }]),
            ...(groupBy.id === 'terminalType.id' ? [
                {
                    sort: 2,
                    id: 'transaction.storeCode',
                    label: translate('resources.transactions.fields.storeCode'),
                    align: 'left'
                }
            ] : []),
            ...(groupBy.id === 'transaction.operatorCode' ? [
                {
                    sort: 2,
                    id: groupBy.fieldDataId,
                    label: translate(groupBy.fieldDataTranslate),
                    align: 'left'
                },
                {
                    sort: 3,
                    id: 'transaction.storeCode',
                    label: translate('resources.transactions.fields.storeCode'),
                    align: 'left'
                },
            ] : []),
            {
                sort: 2 + offset,
                id: 'secondsPerItemLine',
                label:  translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.secondsPerItemLine'),
                align: 'right',
                classes: classes.tableColumnHighlight
            },
            {
                sort: timeTypes.length + 3 + offset,
                id: 'totalTime',
                label: translate('pos.datetime.totalTime'),
                align: 'right',
                formatter: humanizeTime(locale)
            },
            {
                sort: timeTypes.length + 4 + offset,
                id: 'transactionQuantity',
                label: translate('pos.generic.customers'),
                align: 'right'
            },
            {
                sort: timeTypes.length + 5 + offset,
                id: 'transactionsPerHour',
                label: translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.transactionsPerHour'),
                align: 'right'
            },
            {
                sort: timeTypes.length + 6 + offset,
                id: 'itemLineQuantity',
                label: translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.itemLineQuantity'),
                align: 'right'
            },
            ...(groupBy.id !== 'terminalType.id' ? [
                {
                    sort: timeTypes.length + 7 + offset,
                    id: 'options',
                    label: false,
                    align: 'left',
                    noExport: true
                }
            ] : [])
        ];
        headCells = headCells.concat(timeTypes.map((timeType, index) => ({
            sort: index + 3 + offset,
            id: timeType.id,
            label: translate(timeType.abbrName),
            align: 'right',
            formatter: humanizeTime(locale)
        })));

        return headCells;
    };

    const TotalRow = ({ data, tableCellClass, tableTotalCellClass }) => (
        <TableRow key="total">
            <TableCell
                colSpan={['transaction.storeCode', 'transaction.storeCodeAndDatetime'].includes(groupBy.id) ? 2 : 1}
                className={tableCellClass}
            />
            <TableCell className={tableCellClass}>
                <Typography variant="body2" className={tableTotalCellClass}>
                    {translate('pos.generic.total', 1)}
                </Typography>
            </TableCell>
            {groupBy.id === 'terminalType.id' &&
                <TableCell className={tableCellClass} />
            }
            {groupBy.id === 'transaction.operatorCode' &&
                <TableCell colSpan={2} className={tableCellClass} />
            }
            <TableCell className={tableCellClass} />
            {timeTypes.map(timeType => (
                <TableCell key={timeType.id} align="right" className={tableCellClass}>
                    <DurationField
                        record={data}
                        source={`total-${timeType.id}`}
                        shortFormat
                        className={tableTotalCellClass}
                    />
                </TableCell>
            ))}
            <TableCell align="right" className={tableCellClass}>
                <DurationField
                    record={data}
                    source="totalTotalTime"
                    shortFormat
                    className={tableTotalCellClass}
                />
            </TableCell>
            <TableCell align="right" className={tableCellClass}>
                <NumberField
                    record={data}
                    source="totalTransactionQuantity"
                    locales={locale}
                    className={tableTotalCellClass}
                />
            </TableCell>
            <TableCell className={tableCellClass} />
            <TableCell align="right" className={tableCellClass}>
                <NumberField
                    record={data}
                    source="totalItemLineQuantity"
                    locales={locale}
                    className={tableTotalCellClass}
                />
            </TableCell>
            {groupBy.id !== 'terminalType.id' &&
                <TableCell className={tableCellClass} />
            }
        </TableRow>
    );

    const handleClickShow = (event, row) => {
        showDetails({ row, filters, setDefaultFilter, setTransactionFilter, setRow, setOpenModal });
    };

    const handleCloseModal = () => {
        setTransactionFilter();
        setRow();
        setOpenModal(false);
    };

    return (
        <div>
            <CustomTable
                data={processedData}
                loading={loading || processingData}
                filters={filters}
                rowIdKey={['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) ? 'id' : groupBy.fieldId}
                headCells={getHeadCells()}
                orderByField="secondsPerItemLine"
                orderType="asc"
                tableToolbar={TableToolbar}
                totalRow={TotalRow}
            >
                {({ row, handleClick, isItemSelected, labelId, tableCellClass }) => {
                    if (groupBy.id !== 'transaction.storeCodeAndDatetime' && !(groupBy.fieldId in row)) {
                        return null;
                    }
                    if (groupBy.id === 'transaction.storeCodeAndDatetime' && !('transaction.storeCode' in row)) {
                        return null;
                    }

                    if (groupBy.id === 'transaction.storeCode') {
                        if (!row.operators) {
                            return null;
                        }

                        return (
                            <Row
                                key={row[groupBy.fieldId]}
                                aria-checked={isItemSelected}
                                data={row}
                                filters={filters}
                                labelId={labelId}
                                tableCellClass={tableCellClass}
                                checkbox={
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isItemSelected}
                                            onClick={event => handleClick(event, row[groupBy.fieldId])}
                                            inputProps={{'aria-labelledby': labelId}}
                                            color="primary"
                                        />
                                    </TableCell>
                                }
                                detailButton={
                                    <RaButton
                                        onClick={event => handleClickShow(event, row)}
                                        label="pos.generic.detail"
                                    >
                                        <VisibilityIcon/>
                                    </RaButton>
                                }
                            />
                        );
                    }

                    if (groupBy.id === 'transaction.storeCodeAndDatetime') {
                        if (!row.datetimes) {
                            return null;
                        }

                        return (
                            <StoreRow
                                key={row[groupBy.fieldId]}
                                aria-checked={isItemSelected}
                                data={row}
                                filters={filters}
                                labelId={labelId}
                                tableCellClass={tableCellClass}
                                checkbox={
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isItemSelected}
                                            onClick={event => handleClick(event, row[groupBy.fieldId])}
                                            inputProps={{'aria-labelledby': labelId}}
                                            color="primary"
                                        />
                                    </TableCell>
                                }
                                detailButton={
                                    <RaButton
                                        onClick={event => handleClickShow(event, row)}
                                        label="pos.generic.detail"
                                    >
                                        <VisibilityIcon/>
                                    </RaButton>
                                }
                            />
                        );
                    }

                    return (
                        <TableRow
                            key={getKey(groupBy)(row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            hover
                        >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={isItemSelected}
                                    onClick={event => {
                                        const rowIdKey = ['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) ? 'id' : groupBy.fieldId;
                                        handleClick(event, row[rowIdKey]);
                                    }}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    color="primary"
                                />
                            </TableCell>
                            {groupBy.id !== 'terminalType.id' &&
                                <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    className={tableCellClass}
                                >
                                    <TextField
                                        record={row}
                                        source={groupBy.id !== 'chain.id' ? groupBy.id : groupBy.fieldDataId}
                                    />
                                </TableCell>
                            }
                            {groupBy.id === 'terminalType.id' &&
                                <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    className={tableCellClass}
                                >
                                    <TextField
                                        record={row}
                                        source="terminalType.name"
                                        emptyText={translate('resources.transactions.fields.withoutTerminalType')}
                                    />
                                </TableCell>
                            }
                            {groupBy.id === 'transaction.operatorCode' &&
                                <TableCell className={tableCellClass}>
                                    <TextField record={row} source="operator.name" emptyText={emptyText} />
                                </TableCell>
                            }
                            {['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) &&
                                <TableCell className={tableCellClass}>
                                    <TextField record={row} source="transaction.storeCode" emptyText={emptyText} />
                                </TableCell>
                            }
                            <TableCell align="right" className={clsx(tableCellClass, classes.tableColumnHighlight)}>
                                <NumberField
                                    record={row}
                                    source="secondsPerItemLine"
                                    locales={locale}
                                    emptyText={emptyText}
                                />
                            </TableCell>
                            {timeTypes.map(timeType => (
                                <TableCell key={timeType.id} align="right" className={tableCellClass}>
                                    <DurationField record={row} source={timeType.id} shortFormat />
                                </TableCell>
                            ))}
                            <TableCell align="right" className={tableCellClass}>
                                <DurationField record={row} source="totalTime" shortFormat />
                            </TableCell>
                            <TableCell align="right" className={tableCellClass}>
                                <NumberField record={row} source="transactionQuantity" locales={locale} />
                            </TableCell>
                            <TableCell align="right" className={tableCellClass}>
                                <NumberField
                                    record={row}
                                    source="transactionsPerHour"
                                    locales={locale}
                                    emptyText={emptyText}
                                />
                            </TableCell>
                            <TableCell align="right" className={tableCellClass}>
                                <NumberField record={row} source="itemLineQuantity" locales={locale} />
                            </TableCell>
                            {groupBy.id !== 'terminalType.id' &&
                                <TableCell>
                                    <RaButton
                                        onClick={event => handleClickShow(event, row)}
                                        label="pos.generic.detail"
                                    >
                                        <VisibilityIcon />
                                    </RaButton>
                                </TableCell>
                            }
                        </TableRow>
                    );
                }}
            </CustomTable>
            {row && transactionFilter && (
                <TransactionDialog
                    row={row}
                    openModal={openModal}
                    handleCloseModal={handleCloseModal}
                    transactionFilter={transactionFilter}
                    defaultFilter={defaultFilter}
                    filters={filters}
                />
            )}
        </div>
    );
};

const ExportableDataTable = ({ filters, data, loading, cancel, error }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { groupById } = filters;

    const groupBy = useMemo(() => {
        return groupByChoices.find(e => e.id === groupById);
    }, [groupById]);

    useEffect(() => {
        processData({ translate, data, loading, cancel, groupBy, setProcessingData, setProcessedData });
    }, [translate, data, loading, cancel, groupBy]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        {groupBy.id !== 'terminalType.id' &&
                            <TableCell>
                                {translate(groupBy.altName)}
                            </TableCell>
                        }
                        {['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) && (
                            <React.Fragment>
                                <TableCell>
                                    {translate(groupBy.fieldDataTranslate)}
                                </TableCell>
                                <TableCell>
                                    {translate('resources.transactions.fields.storeCode')}
                                </TableCell>
                            </React.Fragment>
                        )}
                        <TableCell className={classes.tableColumnHighlight}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.secondsPerItemLine')}
                        </TableCell>
                        {timeTypes.map(timeType => (
                            <TableCell align="right" key={timeType.id}>
                                {translate(timeType.abbrName)}
                            </TableCell>
                        ))}
                        <TableCell align="right">
                            {translate('pos.datetime.totalTime')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('pos.generic.customers')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.transactionsPerHour')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.itemLineQuantity')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell
                                colSpan={groupBy.id === 'terminalType.id' ?
                                    7 + timeTypes.length :
                                    groupBy.id === 'transaction.operatorCode' ?
                                        8 + timeTypes.length : 6 + timeTypes.length
                                }
                                align="center"
                            >
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.data.map(row => (
                            <TableRow key={getKey(groupBy)(row)}>
                                {groupBy.id !== 'terminalType.id' &&
                                    <TableCell>
                                        <TextField
                                            record={row}
                                            source={groupBy.id === 'chain.id' ? groupBy.fieldDataId : groupBy.fieldId}
                                        />
                                    </TableCell>
                                }
                                {groupBy.id === 'terminalType.id' &&
                                    <TableCell>
                                        <TextField
                                            record={row}
                                            source="terminalType.name"
                                            emptyText={translate('resources.transactions.fields.withoutTerminalType')}
                                        />
                                    </TableCell>
                                }
                                {groupBy.id === 'transaction.operatorCode' &&
                                    <TableCell>
                                        <TextField record={row} source="operator.name" emptyText={emptyText} />
                                    </TableCell>
                                }
                                {['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) &&
                                    <TableCell>
                                        <TextField record={row} source="transaction.storeCode" />
                                    </TableCell>
                                }
                                <TableCell align="right" className={classes.tableColumnHighlight}>
                                    <NumberField
                                        record={row}
                                        source="secondsPerItemLine"
                                        locales={locale}
                                        emptyText={emptyText}
                                    />
                                </TableCell>
                                {timeTypes.map(timeType => (
                                    <TableCell key={timeType.id} align="right">
                                        <DurationField record={row} source={timeType.id} shortFormat />
                                    </TableCell>
                                ))}
                                <TableCell align="right">
                                    <DurationField record={row} source="totalTime" shortFormat />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField record={row} source="transactionQuantity" locales={locale} />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="transactionsPerHour"
                                        locales={locale}
                                        emptyText={emptyText}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField record={row} source="itemLineQuantity" locales={locale} />
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key="total">
                            <TableCell>
                                <Typography variant="body2" className={classes.totalCell}>
                                    {translate('pos.generic.total', 1)}
                                </Typography>
                            </TableCell>
                            {groupBy.id === 'terminalType.id' &&
                                <TableCell />
                            }
                            {groupBy.id === 'transaction.operatorCode' &&
                                <TableCell colSpan={2} />
                            }
                            <TableCell />
                            {timeTypes.map(timeType => (
                                <TableCell key={timeType.id} align="right">
                                    <DurationField
                                        record={processedData}
                                        source={`total-${timeType.id}`}
                                        shortFormat
                                        className={classes.totalCell}
                                    />
                                </TableCell>
                            ))}
                            <TableCell align="right">
                                <DurationField
                                    record={processedData}
                                    source="totalTotalTime"
                                    shortFormat
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="totalTransactionQuantity"
                                    locales={locale}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell />
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="totalItemLineQuantity"
                                    locales={locale}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let {
        groupById, ignoreZeroTimes, precision, businessDayDate, chainId, storeCode, terminalTypeId, terminalNumber,
        operatorCode, orderType, limit
    } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const groupBy = groupByChoices.find(e => e.id === groupById);
        const params = {
            type: 'general-times',
            groupBy: groupBy.fieldId,
            ignoreZeroTimes: ignoreZeroTimes,
            ...(groupBy.groupByDatetime && { groupByDatetime: true }),
            ...(groupBy.withPrecision && { precision: precision }),
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalTypeId: terminalTypeId,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode,
            orderType: orderType,
            ...(groupBy.withLimit && { limit: limit })
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { data } = response.data;
                setData(data.length > 0 ? data : []);
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [
        renderKey, groupById, ignoreZeroTimes, precision, businessDayDate, chainId, storeCode, terminalTypeId,
        terminalNumber, operatorCode, orderType, limit
    ]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const TransactionsTimesBar = () => {
    const translate = useTranslate();
    const ability = useContext(AbilityContext);

    const filters = {
        groupById: getGroupByDefaultValue(
            groupByChoices.map(e => e.id), 'general-times',
            ability.rulesFor('find', 'statistics'), 'transaction.operatorCode'
        ),
        ignoreZeroTimes: true,
        precision: 'day',
        businessDayDate: getBusinessDayDateDefaultValue(),
        orderType: 'ASC',
        limit: 25,
        timeLimit: 5
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.timesSaleTransactions.name')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Filter
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                                onSubmit={onSubmit}
                                cancelTokenSource={cancelTokenSource}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Chart
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DataTable
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default TransactionsTimesBar;
